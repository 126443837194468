<template>
  <div
    class="rounded-[8px] bg-ems-gray700 !p-4 flex items-center gap-4 suggest-obj"
  >
    <a-checkbox
      v-model:checked="item.checked"
      @change="selectedIO"
    ></a-checkbox>
    <div class="flex flex-col">
      <div class="flex gap-3 items-center">
        <img
          :src="
            !item.imagesAvatar
              ? require('@/static/img/no-img.png')
              : item.imagesAvatar
          "
          :alt="item.imagesAvatar"
          width="89"
          height="89"
          class="rounded-full w-[89px] h-[89px] object-cover flex-shrink-0"
        />
        <div class="flex flex-col gap-2 justify-center">
          <span v-if="item.name.indexOf(searchValue) > -1">
            {{ item.name.substr(0, item.name.indexOf(searchValue)) }}
            <span class="text-ems-main2 font-semibold text-base">{{
              searchValue
            }}</span>
            {{
              item.name.substr(
                item.name.indexOf(searchValue) + searchValue.length
              )
            }}
          </span>
          <span class="font-semibold text-base text-ems-gray100" v-else>{{
            item.name
          }}</span>
          <span
            class="px-[10px] py-[3px] text-ems-gray100 block bg-ems-gray600 rounded-[4px] w-[fit-content]"
            >{{ t(IO_TYPE[item.type].label) }}
          </span>
        </div>
      </div>
      <div
        v-if="item.ioGroups && item.ioGroups.length > 0"
        class="flex items-center gap-2 mt-[21px]"
      >
        <span
          v-if="item.ioGroups.length > 0"
          class="text-sm rounded-[4px] text-ems-gray400 px-[6px] py-1 border-1 border-solid border-ems-gray400"
        >
          {{
            listGroups[item.ioGroups[0]]
              ? listGroups[item.ioGroups[0]].name
              : ''
          }}
        </span>
        <span
          v-if="item.ioGroups.length > 1"
          class="text-sm rounded-[4px] text-ems-gray400 px-[6px] py-1 border-1 border-solid border-ems-gray400"
        >
          {{
            listGroups[item.ioGroups[1]]
              ? listGroups[item.ioGroups[1]].name
              : ''
          }}
        </span>
        <a-popover
          v-if="item.ioGroups.length > 2"
          placement="top"
          overlayClassName="popup-table-user"
        >
          <template #content>
            <span
              class="inline-block text-sm rounded-[4px] text-ems-gray400 px-[6px] py-1 border-1 border-solid border-ems-gray400 m-2"
              v-for="(data, idx) in item.ioGroups"
              :key="data.id"
            >
              {{ listGroups[data] ? listGroups[data].name : '' }}
            </span>
          </template>
          <span
            class="inline-block rounded-[4px] px-[6px] py-1 border-1 border-solid border-ems-gray400"
          >
            ...
          </span>
        </a-popover>
      </div>
    </div>
  </div>
</template>
<script setup>
import { i18n } from '@/main';
import { computed, onMounted, ref, defineEmits } from 'vue';
import { IO_TYPE } from '@/config/Constant.js';
const { t } = i18n.global;
const showExpand = ref(true);
const emit = defineEmits(['selected-io']);
const props = defineProps({
  item: { default: {} },
  searchValue: { default: '' },
  listGroups: { default: {} },
});

const selectedIO = () => {
  emit('selected-io', props.item);
};
</script>

<style lang="scss">
.suggest-obj .ant-checkbox-checked .ant-checkbox-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 91, 159, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(91, 91, 159, var(--tw-border-opacity));
}
.suggest-obj .ant-checkbox-inner {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(180, 180, 180, var(--tw-border-opacity));
}
.popup-table-user .ant-popover-arrow-content {
  background-color: #1f1f1f !important;
}
.popup-table-user .ant-popover-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 17, 29, var(--tw-bg-opacity));
  border-radius: 4px;
}
</style>
